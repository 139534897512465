import { Fade, ThemeProvider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Suspense, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { loadingState } from './shared/store/slices/loadingSlice';

import images from './assets/images';
import Sidenav from './components/sidenav';
import AppRouting from './shared/AppRouting';
import instance from './shared/interceptor';
import LoaderComp from './shared/shared-comp/LoaderComp';
import ScrollToTop from './shared/shared-comp/ScrollToTop';
import { errorState } from './shared/store/slices/errorSlice';
import {
  setSuccessMsg,
  successState,
} from './shared/store/slices/successSlice';
import { addUserData, setCompany } from './shared/store/slices/userSlice';
import { COMPANY, SESSION_DETAIL } from './shared/util/constant';
import {
  clearStorage,
  deleteCookie,
  getCookie,
  globalError,
  setCompanyToStorage,
  setLoader,
  setUserToStorage,
} from './shared/util/util';
import { theme } from './theme/theme';
const MemoSidenav = memo(Sidenav);

function App() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const loadingSel = useSelector(loadingState);

  const errorMsg = useSelector(errorState);
  const successMsg = useSelector(successState);
  const userCookie = getCookie(process.env.REACT_APP_USER);
  const token = userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;

  window.onfocus = async () => {
    let location = window.location.pathname;
    if (
      !token &&
      !location.includes('forgot-password') &&
      !location.includes('signup')
    ) {
      await isTokenPresent();
    }
  };

  useEffect(() => {
    if (errorMsg?.error === 'User not found') {
      deleteCookie(process.env.REACT_APP_USER);
      clearStorage();
      if (
        window.location.pathname?.includes('reset-password') ||
        window.location.pathname?.includes('signin') ||
        window.location.pathname?.includes('signup')
      )
        return;
      window.location.reload();
    }
    if (errorMsg?.error) {
      enqueueSnackbar(errorMsg?.error, {
        variant: 'error',
        TransitionComponent: Fade,
        className: `is-error message alert-message-box bg-white`,
        preventDuplicate: true,
      });
    }
    if (successMsg?.success) {
      enqueueSnackbar(successMsg?.success, {
        variant: 'success',
        TransitionComponent: Fade,
        className: `is-success message alert-message-box bg-white`,
        preventDuplicate: true,
      });
    }
    setTimeout(() => {
      globalError(dispatch, '');
      dispatch(setSuccessMsg(''));
    }, 6000);
  }, [errorMsg?.error, successMsg?.success]);

  const isTokenPresent = async () => {
    try {
      const studioId = userCookie ? JSON.parse(userCookie)?.['id'] : null;
      const res = await instance.get(SESSION_DETAIL, {
        headers: {
          'studio-id': studioId,
        },
      });
      const companyRes = await instance.get(
        `${COMPANY}/${res['user']?.company?.id}`
      );
      setUserToStorage(dispatch, addUserData, res, true);
      setCompanyToStorage(dispatch, setCompany, companyRes);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  // ============================================

  return (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <div className="B-loading-wrapper">
            <img
              src={(images as any).buAnim}
              alt="B"
              className="builder-logo-img"
            />
          </div>
        }
      >
        <Router>
          <>
            <ScrollToTop />
            {loadingSel?.loading && (
              <div className="B-loading-wrapper">
                <img
                  src={(images as any).buAnim}
                  alt="B"
                  className="builder-logo-img"
                />
              </div>
            )}
            {token ? <MemoSidenav /> : null}
            <AppRouting />
          </>
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
