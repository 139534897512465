import instance from "../../shared/interceptor";
import { addPermissions, addUserData, addUserPermissions, resetUser, setCompany } from "../../shared/store/slices/userSlice";
import { COMPANY, SESSION_DETAIL, SIGNIN, SIGNOUT } from "../../shared/util/constant";
import { clearStorage, deleteCookie, getCookie, globalError, navigateAccordingToRoleAndPaymentStatus, setCompanyToStorage, setCookie, setLoader, setUserToStorage } from "../../shared/util/util";

export const signInAs = async (profile, compSel, dispatch, startTransition, navigate, from = null) => {
  let foundUser = compSel?.demo_company_users?.find(mem => mem?.role_name?.toLowerCase() == profile?.toLowerCase());

  if (foundUser) {
    try {
      let payload = {
        user: {
          email: foundUser?.email,
          password: foundUser?.encrypted_password,
          device_name: navigator?.userAgent
        }
      }
      let res = await instance.post(SIGNIN, payload) as any;
      await logout(dispatch);
      getCompanyDetail(res, dispatch, startTransition, navigate, from);
    } catch (e: any) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  } else {
    globalError(dispatch, {message: 'User not found.'});
  }
}

const getCompanyDetail = (userRes, dispatch, startTransition, navigate, from) => {
  try {
    const userCookie = getCookie(process.env.REACT_APP_USER);
    const studioId = userCookie ? JSON.parse(userCookie)?.['id'] : null;
    setCookie(process.env.REACT_APP_USER, JSON.stringify({ authtoken: userRes?.['user']?.['login_token'], pm_dashboard_token: userRes?.['user']?.['pm_dashboard_token'], id: userRes?.['user']?.['studio_user_pid'] }));
   
    dispatch(addPermissions(userRes?.['user']?.['all_permissions']));
    dispatch(addUserPermissions(userRes?.['user']?.['user_permissions']));
    setTimeout(async() => {
      const res = await instance.get(SESSION_DETAIL, { headers: { 'studio-id': studioId } });
      const companyRes = await instance.get(`${COMPANY}/${res['user']?.company?.id}`);
      setCookie(process.env.REACT_APP_USER_PLAN, JSON.stringify({ care_type: companyRes['company']?.care_type, disabled_care_type: companyRes['company']?.disabled_care_type, hidden_care_type: companyRes['company']?.hidden_care_type, name: companyRes['company']?.name, speed_id: companyRes['company']?.speed_id }));
      setUserToStorage(dispatch, addUserData, res, true);
      setCompanyToStorage(dispatch, setCompany, companyRes);
      dispatch(setCompany(companyRes['company']));
      if (!from) {
        navigateAccordingToRoleAndPaymentStatus(startTransition, navigate, companyRes['company'], res['user']);
      } else {
        navigate(from);
      }
    }, 10)
  } catch (e: any) {
    globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
}

const logout = async (dispatch) => {
  try {
    setLoader(dispatch, true);
    await instance.delete(SIGNOUT);
    deleteCookie(process.env.REACT_APP_USER);
    clearStorage();
    dispatch(resetUser());
  } catch (e) {
    globalError(dispatch, e);
  } finally {
    setLoader(dispatch, false);
  }
}