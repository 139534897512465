/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { startTransition, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import images from '../../assets/images';
import instance from '../../shared/interceptor';
import { addUserData, setCompany } from '../../shared/store/slices/userSlice';
import { COMPANY, CONFIRM, SESSION_DETAIL, SIGNIN } from '../../shared/util/constant';
import { encryptPassword, getCookie, globalError, navigateAccordingToRoleAndPaymentStatus, setCompanyToStorage, setCookie, setLoader, setUserToStorage, validateEmail } from '../../shared/util/util';
import QueryParamsHOC from './QueryParamsHOC';

const Login = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [formVal, setFormVal] = useState({
    user: {
      email: '',
      password: ''
    }
  });
  const [showPage, setShowPage] = useState(false);

  const userCookie = getCookie(process.env.REACT_APP_USER);
  const token =  userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;


  useEffect(() => {
    (async () => await isTokenPresent())();
  }, [props.params, token]);

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  }

  const confirmUser = async (params) => {
    try {
      setLoader(dispatch, true);
      let payload = {
        user: {
          email: params.email
        },
        confirmation: params.confirmation
      }
      let res = await instance.post(CONFIRM, payload);
      if (res['user']['login_token']) {
        setShowPage(false);
        setUserToStorage(dispatch, addUserData, res);
        if (res?.['user']?.['company']?.['id']) {
          let companyRes = await instance.get(`${COMPANY}/${res['user']['company']['id']}`);
          setCompanyToStorage(dispatch, setCompany, companyRes);
          routeToSpecific(companyRes['company'], res['user']);
        }
      } else {
        setShowPage(true);
      }
    } catch (e) {
      globalError(dispatch, e);
      setShowPage(true);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const inputChange = (event) => {
    setError('');
    let key = event.target.name;
    setFormVal({ user: { ...formVal.user, [key]: event.target.value } });
  }

  const validatePayload = (userObj) => {
    if (!userObj?.password || !userObj?.email) {
      setError('Email and Password are required');
      return false;
    }
    if (!validateEmail(userObj.email)) {
      setError('Provide a valid email address');
      return false;
    }
    return true;
  }

  const submitLoginForm = async () => {
    let userObj = formVal?.user;
    if (validatePayload(userObj)) {
      setError('');
      setLoader(dispatch, true);
      try {
        let payload = {
          user: {
            ...userObj,
            password: encryptPassword(userObj.password),
            device_name: navigator?.userAgent
          }
        }
        let res = await instance.post(SIGNIN, payload) as any;
        setCookie(process.env.REACT_APP_USER, JSON.stringify({ pm_dashboard_token: res?.['user']?.['pm_dashboard_token'], id: res?.['user']?.['studio_user_pid'] }));
        setUserToStorage(dispatch, addUserData, res);

        isTokenPresent();

      } catch (e: any) {
        if (e?.response?.data?.error?.includes('Invalid login credentials')) {
          setError('Invalid Login credentials. Verify your email and password');
        } else {
          globalError(dispatch, e);
        }
      } finally {
        setLoader(dispatch, false);
      }
    }
  }

  const submitForm = (e) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      submitLoginForm();
    }
  }

  const isTokenPresent = async () => {
    const studioId = userCookie ? JSON.parse(userCookie)?.['id'] : null;
    if (props?.params) {
      await confirmUser(props.params);
    } else if(token && !props?.params) {
      try {
        setShowPage(false);
        setLoader(dispatch, true)
        const res = await instance.get(SESSION_DETAIL, { headers: { 'studio-id': studioId } });
        const companyRes = await instance.get(`${COMPANY}/${res['user']?.company?.id}`);
        setCookie(process.env.REACT_APP_USER_PLAN, JSON.stringify({ care_type: companyRes['company']?.care_type, disabled_care_type: companyRes['company']?.disabled_care_type, hidden_care_type: companyRes['company']?.hidden_care_type, name: companyRes['company']?.name, speed_id: companyRes['company']?.speed_id }));
        setUserToStorage(dispatch, addUserData, res, true);
        setCompanyToStorage(dispatch, setCompany, companyRes);
        routeToSpecific(companyRes['company'], res['user']);
      } catch (e) {
        setShowPage(true);
        if (e == 'User not found') {
          setLoader(dispatch, false);
          return;
        }
        globalError(dispatch, e);
      } finally {
        setLoader(dispatch, false);
      }
    } else {
      setShowPage(true);
    }
  }

  const routeToSpecific = (company, user) => {
    let location = window.location.pathname;
    if (from) {
      navigate(from);
    } else if (company?.demo_company_users?.length > 0 && location.includes('signin')) {
      navigate('/demo-signin');
    } else {
      navigateAccordingToRoleAndPaymentStatus(startTransition, navigate, company, user);
    }
  }

  //==============================================
  
  return ( !showPage ? null :
    <section className="login-page">
      <div className="login-wrapper">
        <div className="login-outer">          
          <div className="signup-title text-center">
            <em className="logo">
              <img src={images.builderEnterpriseLogo} alt="Builder Enterprise" />
            </em>
            <h1>Sign in to your account</h1>
          </div>
          <form onKeyDown={submitForm}>
            <div className={`form-group ${error ? 'has-error' : '' }`}>
              <FormControl variant="filled">
                <InputLabel>Email</InputLabel>
                {/* @ts-ignore */}
                <FilledInput inputProps={{ "data-testid": "email" }} type="email" name="email" variant="filled" onChange={(e) => inputChange(e)} />
              </FormControl>
            </div>

            <div className={`form-group secondary ${error ? 'has-error' : '' }`}>
              <FormControl variant="filled">
                <InputLabel>Password</InputLabel>
                <FilledInput
                  name="password"
                  inputProps={{ "data-testid": "password" }}
                  onChange={(e) => inputChange(e)}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        data-testid="pass-icon"
                        aria-label="toggle password visibility"
                        onClick={(e) => handleClickShowPassword(e)}
                        edge="end"
                      >
                        {showPassword ? <em className='be-eye'></em> : <em className='be-eye-off'></em>}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <span className="form-error">{ error }</span>
            </div>

            <div className="forgot-link">
              <Link to='/forgot-password' className="primary-link">Forgot password?</Link >
            </div>

            <div className="btn-block">
              <Button data-testid='signin' className='green-btn' variant="contained" onClick={submitLoginForm as any}>Sign in</Button>
            </div>
          </form>
        </div >
      </div >
    </section >
  )
}

export default QueryParamsHOC(Login);
