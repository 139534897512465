import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../../assets/images';
import AddMemberToBmeetDialog from '../../components/dialog/bmeet-member-dialog/AddMemberToBmeetDialog';
import DeleteMeetingDialog from '../../components/dialog/bmeet-member-dialog/DeleteMeetingDialog';
import instance from '../../shared/interceptor';
import {
  bmeetUrl,
  companyState,
  userState,
} from '../../shared/store/slices/userSlice';
import { SPACES } from '../../shared/util/constant';
import {
  formatDate,
  getFullname,
  globalError,
  isPrivateSpaceOwner,
  setLoader,
} from '../../shared/util/util';
import './project.scss';
import Summary from './summary';
//import ProjectTopNavigation from '/ProjectNavigation';
import SpaceTopNavigation from './SupplyChainDistributionNavigation';
import './space-meetings.scss';
import UserDropdown from '../../shared/shared-comp/UserDropdown';
import LoaderComp from '../../shared/shared-comp/LoaderComp';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import UnauthorizedAccess from '../../components/UnauthorizedAccess';

let userColors = [`#E1C3FF`, `#FFC7B8`, `#C8F8FF`, `#ECEDF0`];

export default function SpaceMeetings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const compSel = useSelector(companyState);
  const userSel = useSelector(userState);
  const currentSpace = useSelector(currentSpaceState);

  const [tabContentVal, setValue] = useState('upcomingMeetingsSection');
  const [meetingList, setMeetingList] = useState(null);
  const [callMyTeam, setCallMyTeam] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [deleteMeeting, setDeleteMeeting] = useState(null);
  const [meetingLoading, setMeetingLoading] = useState(true);
  const [isEmptyUpcoming, setIsEmptyUpcoming] = useState(false);
  const [isEmptyPast, setIsEmptyPast] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    getMeetings();
  }, []);

  const getMeetings = async () => {
    try {
      setMeetingLoading(true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/meetings`;
      let meetingsRes = await instance.get(url);
      setMeetingList(meetingsRes?.['meetings']);
      setIsEmptyUpcoming(!meetingsRes?.['meetings']?.upcoming?.length);
      setIsEmptyPast(!meetingsRes?.['meetings']?.past?.length);
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setMeetingLoading(false);
    }
  };

  const joinMeeting = (meet) => {
    dispatch(bmeetUrl(meet));
    navigate(`/company/spaces/${params?.id}/meeting-playground/${meet?.id}`);
  };

  const downloadMeeting = (url, title) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, `${title}.mp4`);
      })
      .catch((err) => {
        globalError(dispatch, err);
      });
  };

  const editMeeting = (meet) => {
    setSelectedMeeting(meet);
    setCallMyTeam(true);
  };

  const callMyTeamDialogClose = (e, render = false) => {
    if (render) {
      getMeetings();
    }
    setCallMyTeam(false);
  };

  const handleModalClose = (e, reason, render = false, meetingId = null) => {
    if (reason && reason === 'backdropClick') return;
    if (render) {
      setMeetingList((prev) => ({
        ...prev,
        upcoming: prev?.upcoming.filter((meet) => meet.id !== meetingId),
      }));
    }
    setDeleteMeeting(null);
  };

  const isMeetingHappening = (meet) =>
    meet?.instant ||
    (moment(meet?.start_time).isBefore(moment()) &&
      moment(meet?.end_time).isAfter(moment()));

  //===========================================================
  return isPrivateSpaceOwner(currentSpace, userSel) ? (
    <div
      className="space-overview tab-page-section"
      aria-label="space-meetings-page"
    >
      <div className="page-container pb-0 top-space">
        <SpaceTopNavigation />

        <section className="timeline-wrapper">
          <div className="page-content space-meetings-page">
            {/* Upcoming and past meeting tabs */}
            <div className="tab-content-wrapper">
              <TabContext value={tabContentVal}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="">
                    <Tab
                      aria-label="upcoming-btn"
                      label="Upcoming meetings"
                      value="upcomingMeetingsSection"
                      className="me-0"
                    />
                    <Tab
                      aria-label="past-btn"
                      label="Past meetings"
                      value="pastMeetingsSection"
                    />
                  </TabList>
                </Box>
                <TabPanel value="upcomingMeetingsSection">
                  <div className="tabListView space-meeting-section-scroll">
                    {meetingLoading && <LoaderComp maxHeight="40vh" />}
                    <div className="tab-content-section">
                      {!meetingLoading &&
                        meetingList?.upcoming
                          ?.sort(
                            (a, b) =>
                              moment(a?.start_time)?.valueOf() -
                              moment(b?.start_time)?.valueOf()
                          )
                          ?.map((meet) => {
                            return (
                              <div
                                aria-label="upcoming-meeting"
                                className="tab-content-row"
                                key={meet?.id}
                              >
                                <div className="col1">
                                  <div
                                    className="badge"
                                    style={
                                      isMeetingHappening(meet)
                                        ? { backgroundColor: '#00C853' }
                                        : { backgroundColor: '#3D5AFE' }
                                    }
                                  >
                                    {isMeetingHappening(meet)
                                      ? 'Now'
                                      : `${formatDate(meet?.start_time)} | ${moment(meet?.start_time).format('hh:mm a')} ${meet?.end_time && '-'} ${meet?.end_time && moment(meet?.end_time).format('hh:mm a')}`}
                                  </div>
                                  <div className="title">
                                    {meet?.title}{' '}
                                    {meet?.instant &&
                                      moment(meet?.start_time).format(
                                        'DD MMM, YYYY hh:mm a'
                                      )}
                                  </div>
                                </div>
                                <div className="col2">
                                  <div className="info-title">
                                    HOST:
                                    <Tooltip
                                      enterTouchDelay={0}
                                      title={
                                        <div className="tooltip-content">
                                          {getFullname(
                                            meet?.host?.first_name,
                                            meet?.host?.last_name
                                          )}
                                        </div>
                                      }
                                    >
                                      {meet?.host?.profile_picture
                                        ?.profile_picture_url ? (
                                        <img
                                          className="avatar"
                                          src={
                                            meet?.host?.profile_picture
                                              ?.profile_picture_url
                                          }
                                          alt="avatar"
                                        />
                                      ) : (
                                        <span
                                          className="avatar"
                                          style={{ backgroundColor: '#D9C4FF' }}
                                        >
                                          {meet?.host?.first_name?.[0]}
                                        </span>
                                      )}
                                    </Tooltip>
                                  </div>
                                  {meet?.users?.length > 0 && (
                                    <div className="info-title">
                                      INVITEE:
                                      {meet?.users
                                        ?.slice(0, 3)
                                        .map((item, idx) => (
                                          <Tooltip
                                            key={item.id}
                                            enterTouchDelay={0}
                                            title={getFullname(
                                              item?.first_name,
                                              item?.last_name
                                            )}
                                          >
                                            {item?.profile_picture
                                              ?.profile_picture_url ? (
                                              <img
                                                className="avatar overlapped"
                                                src={
                                                  item?.profile_picture
                                                    ?.profile_picture_url
                                                }
                                                alt="avatar"
                                              />
                                            ) : (
                                              <span
                                                className="avatar overlapped"
                                                style={{
                                                  backgroundColor:
                                                    userColors[idx],
                                                }}
                                              >
                                                {item?.first_name?.[0]}
                                              </span>
                                            )}
                                          </Tooltip>
                                        ))}
                                      {meet.users.length > 3 && (
                                        <UserDropdown users={meet.users} />
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col3">
                                  {!isMeetingHappening(meet) &&
                                    (userSel?.id === meet?.host?.id ||
                                      userSel?.role?.name == 'CIO') && (
                                      <span className="icon-buttons-wrap">
                                        <Tooltip
                                          placement="bottom-end"
                                          title="Edit"
                                          classes={{
                                            popper: 'info-tooltip bottom-end',
                                          }}
                                          arrow
                                        >
                                          <Button
                                            data-testid="edit-btn"
                                            variant="outlined"
                                            className="xs-btn is-icon-btn"
                                            onClick={() => editMeeting(meet)}
                                          >
                                            <img
                                              src={images.editIcon}
                                              alt="Edit"
                                            />
                                          </Button>
                                        </Tooltip>
                                        <Tooltip
                                          placement="bottom-start"
                                          title="Delete"
                                          classes={{ popper: 'info-tooltip' }}
                                          arrow
                                        >
                                          <Button
                                            data-testid="delete-btn"
                                            variant="outlined"
                                            className="xs-btn is-icon-btn"
                                            onClick={() =>
                                              setDeleteMeeting(meet)
                                            }
                                          >
                                            <img
                                              src={images.deleteIcon}
                                              alt="Delete"
                                            />
                                          </Button>
                                        </Tooltip>
                                      </span>
                                    )}
                                  {(meet?.is_host || meet?.is_invitee) && (
                                    <Button
                                      data-testid="join-btn"
                                      className="xs-btn purple-btn"
                                      variant="contained"
                                      onClick={() => joinMeeting(meet)}
                                    >
                                      {meet?.is_host ? 'Start' : 'Join'}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      {/* No data wrapper  */}
                      {!meetingLoading && isEmptyUpcoming && (
                        <div className="no-data-wrapper meetings">
                          <em className="thumb">
                            <img
                              src={images.emptyStateIllustration}
                              alt="Spaces Flag"
                            />
                          </em>
                          <h4 aria-label="no-upcoming-meetings">No meetings</h4>
                          <p>Your upcoming meetings will appear here.</p>
                        </div>
                      )}
                      {/* No data wrapper end */}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="pastMeetingsSection">
                  <div className="tabListView space-meeting-section-scroll">
                    {meetingLoading && <LoaderComp maxHeight="40vh" />}
                    {!meetingLoading &&
                      meetingList?.past?.map((meet) => {
                        return (
                          <div
                            aria-label="past-meeting"
                            className="tab-content-section"
                            key={meet?.id}
                          >
                            <div className="tab-content-row">
                              <div className="col1">
                                <div
                                  className="badge"
                                  style={{ backgroundColor: '#676b7e' }}
                                >
                                  {formatDate(meet?.start_time)} |{' '}
                                  {moment(meet?.start_time).format('hh:mm a')}{' '}
                                  {meet?.end_time && '-'}{' '}
                                  {meet?.end_time &&
                                    moment(meet?.end_time).format('hh:mm a')}
                                </div>
                                <div className="title">{meet?.title}</div>
                              </div>
                              <div className="col2">
                                <div className="info-title">
                                  HOST:
                                  <Tooltip
                                    enterTouchDelay={0}
                                    title={
                                      <div className="tooltip-content">
                                        {getFullname(
                                          meet?.host?.first_name,
                                          meet?.host?.last_name
                                        )}
                                      </div>
                                    }
                                  >
                                    {meet?.host?.profile_picture
                                      ?.profile_picture_url ? (
                                      <img
                                        className="avatar"
                                        src={
                                          meet?.host?.profile_picture
                                            ?.profile_picture_url
                                        }
                                        alt="avatar"
                                      />
                                    ) : (
                                      <span
                                        className="avatar"
                                        style={{ backgroundColor: '#D9C4FF' }}
                                      >
                                        {meet?.host?.first_name?.[0]}
                                      </span>
                                    )}
                                  </Tooltip>
                                </div>
                                {meet?.users?.length > 0 && (
                                  <div className="info-title">
                                    INVITEE:
                                    {meet?.users?.slice(0, 3).map((us, idx) => {
                                      return (
                                        <Tooltip
                                          key={us.id}
                                          enterTouchDelay={0}
                                          title={getFullname(
                                            us?.first_name,
                                            us?.last_name
                                          )}
                                        >
                                          {us?.profile_picture
                                            ?.profile_picture_url ? (
                                            <img
                                              className="avatar overlapped"
                                              src={
                                                us?.profile_picture
                                                  ?.profile_picture_url
                                              }
                                              alt="avatar"
                                            />
                                          ) : (
                                            <span
                                              className="avatar overlapped"
                                              style={{
                                                backgroundColor:
                                                  userColors[idx],
                                              }}
                                            >
                                              {us?.first_name?.[0]}
                                            </span>
                                          )}
                                        </Tooltip>
                                      );
                                    })}
                                    {meet.users.length > 3 && (
                                      <UserDropdown users={meet.users} />
                                    )}
                                  </div>
                                )}
                              </div>
                              {meet?.recording_link && (
                                <div className="col3">
                                  <Button
                                    aria-label="download-meet-btn"
                                    className="xs-btn white-btn is-hover-btn download-btn"
                                    variant="contained"
                                    onClick={() =>
                                      downloadMeeting(
                                        meet?.recording_link,
                                        meet?.title
                                      )
                                    }
                                  >
                                    Download Recording
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    {/* No data wrapper  */}
                    {!meetingLoading && isEmptyPast && (
                      <div className="no-data-wrapper meetings">
                        <em className="thumb">
                          <img
                            src={images.emptyStateIllustration}
                            alt="Spaces flag"
                          />
                        </em>
                        <h4 aria-label="no-past-meetings">No meetings</h4>
                        <p>Your past meetings will appear here.</p>
                      </div>
                    )}
                    {/* No data wrapper end */}
                  </div>
                </TabPanel>
              </TabContext>
            </div>
          </div>
          <Summary />

          {callMyTeam && (
            <AddMemberToBmeetDialog
              {...{ callMyTeam, callMyTeamDialogClose, selectedMeeting }}
            />
          )}
          {!!deleteMeeting && (
            <DeleteMeetingDialog {...{ deleteMeeting, handleModalClose }} />
          )}
        </section>
      </div>
    </div>
  ) : (
    <UnauthorizedAccess />
  );
}
