/* eslint eqeqeq: 0 */
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../../../assets/images';
import '../../../pages/building/project.scss';
import instance from '../../../shared/interceptor';
import { getUserListWithoutPermission } from '../../../shared/services/common.service';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { bmeetUrl, companyState } from '../../../shared/store/slices/userSlice';
import { SPACES } from '../../../shared/util/constant';
import { debounce, getFullname, globalError, setLoader } from '../../../shared/util/util';
import './bmeet.scss';

const start = moment(new Date());
const remainder = 30 - (start.minute() % 30);
const dateTime = moment(start).add(remainder, "minutes");
let timeArr = Array.from(new Array(48)).map((_, idx) => moment(dateTime).add(idx * 30, 'minutes').format("HH:mm"));
let freshTimeArr = Array.from(new Array(48)).map((_, idx) => start.startOf('day').add(idx * 30, 'minutes').format("HH:mm"));

function AddMemberToBmeetDialog({ callMyTeam, callMyTeamDialogClose, selectedMeeting = null, isFromIdeaboard = null}) {
  const params = useParams();
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const navigate = useNavigate();

  const [callMyTeamTabContentVal, setCallMyTeamTabContentVal] = useState(selectedMeeting ? 'scheduleMeetingsSection' : 'instantMeetingsSection');
  const [memberList, setMemberList] = useState([]);
  const [selectedMem, setSelectedMem] = useState([]);
  const [instantNatashaEnabled, setInstantNatashaEnabled] = useState(false);
  const [inviteAllForInstant, setInviteAllForInstant] = useState(false);
  const [inviteAllForSchedule, setInviteAllForSchedule] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scheduleFormVal, setScheduleFormVal] = useState({
    title: '',
    meeting_users: [],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    natasha_enabled: false,
    description: '',
    start_date: new Date(),
    start_time: '',
    duration: '',
    conferencing: 'Bmeet'
  });
  const [openCal, setOpenCal] = useState(false);
  const [errorObj, setErrorObj] = useState({
    startDate: '',
    startTime: '',
    meetingTitle: '',
    meetingDesc: ''
  })

  useEffect(() => {
    if (selectedMeeting) {
      setScheduleFormVal(prev => ({...prev, 
        meeting_users: selectedMeeting?.users,
        title: selectedMeeting?.title,
        natasha_enabled: selectedMeeting?.natasha_enabled,
        description: selectedMeeting?.description,
        start_date: moment(selectedMeeting?.start_time) as any,
        start_time: moment(selectedMeeting?.start_time).format('HH:mm'),
        duration: moment.duration(moment(selectedMeeting?.end_time).diff(moment(selectedMeeting?.start_time)))?.asMinutes() as any,
      }));
    }
  }, [])

  moment.updateLocale("en", {
    week: {
      dow: 1
    }
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCallMyTeamTabContentVal(newValue);
  };

  const handleDelete = (idx, isInstant) => {
    let tempArr = isInstant ? [...selectedMem] : [...scheduleFormVal['meeting_users']];
    tempArr.splice(idx, 1);
    isInstant ? setSelectedMem(tempArr) : setScheduleFormVal(prev => ({ ...prev, meeting_users: tempArr }));
  };

  const getUserList = async (query) => {
    const userListRes = await getUserListWithoutPermission(companySel, query, dispatch, setLoading, true);
    setMemberList(userListRes?.['users'] ?? []);
  }

  const searchMembers = debounce((event) => {
    getUserList(event.target.value);
  });

  const addToSelected = (value, isInstant) => {
    let val = [...new Map(value.map(item => [item['id'], item])).values()];
    if (isInstant) {
      setSelectedMem(val);
    } else {
      setScheduleFormVal(prev => ({ ...prev, meeting_users: val }));
    }
  }

  const meetingInviteeList = (inviteAll, membersList) => {
    if (inviteAll && !membersList) {
      return 'all';
    } else if (!inviteAll && membersList) {
      return membersList;
    } else if (inviteAll && memberList) {
        return [ ...(currentSpaceSel?.members || []), ...membersList].map(user => ( { email: user.email } )).filter((user, index, self) => 
          index === self.findIndex(u => u.email === user.email)
        );
    }
    else {
      return [];
    }
  }

  const startMeeting = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', companySel?.id)}/${params?.id}/meetings`;
      let payload = {
        meeting: {
          title: `${currentSpaceSel?.name} meeting`,
          description: `${currentSpaceSel?.name} meeting`,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          natasha_enabled: instantNatashaEnabled,
          meeting_users: meetingInviteeList(inviteAllForInstant, selectedMem)
        }
      }
      let resData = await instance.post(url, payload);
      if (resData['meeting']) {
        dispatch(bmeetUrl(resData['meeting']));
        navigate(`/company/spaces/${params?.id}/meeting-playground/${resData['meeting']?.['id']}`, {
          state: isFromIdeaboard
        });
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const scheduleMeeting = async () => {
    try {
      if (!scheduleFormVal?.['start_date']) {
        return setErrorObj({...errorObj, startDate: 'Enter start date.'});
      } else if (!scheduleFormVal?.['start_time']) {
        return setErrorObj({...errorObj, startTime: 'Enter start time.'});
      }
      
      setLoader(dispatch, true);
      let date = moment(scheduleFormVal?.['start_date']);
      let hourAndMin = moment(scheduleFormVal?.['start_time'], 'HH:mm');
      date.set({ hour: hourAndMin.get('hour'), minute: hourAndMin.get('minute'), second: hourAndMin.get('second') });

      if (!date.isAfter(moment())) {
        return setErrorObj({...errorObj, startTime: 'Start time should be greater than current time.'});
      }
      
      const url = `${SPACES.replace(':id', companySel?.id)}/${params?.id}/meetings`;
      const payload = {
        meeting: {
          title: scheduleFormVal?.['title'] ? scheduleFormVal?.['title'] : `${currentSpaceSel?.name} meeting`,
          meeting_users: meetingInviteeList(inviteAllForSchedule, scheduleFormVal?.['meeting_users']),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          natasha_enabled: scheduleFormVal?.['natasha_enabled'] ?? false,
          description: scheduleFormVal?.['description'] ? scheduleFormVal?.['description'] : `${currentSpaceSel?.name} meeting`,
          start_time: date,
          duration: scheduleFormVal?.['duration'],
          conferencing: 'Bmeet'
        }
      }
      if (payload?.meeting?.title?.length > 50) {
        return setErrorObj({ ...errorObj, meetingTitle: 'Meeting title should not be greater than 50 characters.'})
      } else if (payload?.meeting?.description?.length > 250) {
        return setErrorObj({ ...errorObj, meetingDesc: 'Meeting description should not be greater than 250 characters.'})
      }
      resetErrorObj();

      if (selectedMeeting) {
        await instance.patch(`${url}/${selectedMeeting?.id}`, payload);
      } else {
        await instance.post(url, payload);
      }
      dispatch(setSuccessMsg(`Meeting has been ${selectedMeeting ? 'updated': 'scheduled'} successfully.`));
      if (selectedMeeting || isFromIdeaboard) {
        callMyTeamDialogClose(null, true);
      } else {
        navigate(`/company/spaces/${params?.id}/ideaboard`);
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  }

  const handleModalClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    callMyTeamDialogClose(event);
  }

  const resetErrorObj = () => {
    setErrorObj({
      startDate: '',
      startTime: '',
      meetingTitle: '',
      meetingDesc: ''
    })
  }

  //===========================================================
  return (
    <Dialog
      open={callMyTeam}
      onClose={handleModalClose}
      className='call-may-team-dialogue'
    >
      <DialogContent>
        <div className="modal-body" aria-label='add-bmeet-member'>
          <div className="modal-title has-right-data">
            <h2>Call my team</h2>
          </div>
          {/* <div className="modal-form form-container tab-content-wrapper"> */}
          <div className="modal-form tab-content-wrapper">
            <TabContext value={callMyTeamTabContentVal}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="change-tab">
                  <Tab label="Instant meeting" aria-label="instant" value="instantMeetingsSection" />
                  <Tab label="Schedule meeting" aria-label="schedule" value="scheduleMeetingsSection" />
                </TabList>
              </Box>
              <TabPanel value="instantMeetingsSection" className='tab-panel-wrapper'>
                <div className='tab-content-section'>
                  <div className="title-block">
                    <div className='left-title'>
                      <Switch inputProps={{ 'aria-label': "authToggleSwitch" }} checked={instantNatashaEnabled} onChange={() => setInstantNatashaEnabled(prev => !prev)} />
                      <span className='enable-natasha-text'>Enable Natasha</span>
                      <img className='natasha-icon' src={images.natashaLogo} alt="" />
                    </div>
                  </div>
                  <div className="info-title-block">
                    <div className='left-title'>
                      <h6>Add member</h6>
                    </div>
                    <div className='right-side'>
                      <div>
                        <div className="has-right-data checkbox-container">
                          <FormControlLabel
                            className='checkbox-control'
                            control={<Checkbox
                              aria-label='checkbox'
                              icon={<img src={images.checkboxUnchecked} alt='' />}
                              checkedIcon={<img src={images.checkboxChecked} alt='' />}
                              checked={inviteAllForInstant}
                              onChange={() => {setInviteAllForInstant(prev => !prev); setSelectedMem([])}}
                            />}
                            label={
                              <span>Invite all space members</span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-sm-12">
                      <div className="form-group searchbar-wrap mb-0">
                        <FormControl variant="filled">
                          <Autocomplete
                            loading={loading}
                            // disabled={inviteAllForInstant}
                            data-testid="autocomplete-space-search"
                            disableCloseOnSelect
                            multiple
                            freeSolo
                            disableClearable
                            filterOptions={(options) => options}
                            value={selectedMem}
                            id="searchMembersDropdown"
                            className='search-members-dropdown'
                            classes={{ popper: 'bmeet-search-dropdown' }}
                            onChange={(event, value) => addToSelected(value, true)}
                            options={memberList}
                            autoHighlight
                            renderTags={(value: string[], getTagProps) => ''}
                            getOptionLabel={(option) => option?.email}
                            popupIcon={<em className='be-search'></em>}
                            renderOption={(props, option, { selected }) => (
                              <Box component="span" {...props} key={option?.id}>
                                <div className='search-members-dropdown'>
                                  <div className='left-side'>
                                  {option?.profile_picture?.profile_picture_url ? <img alt="profile-pic" className='initials' src={option?.profile_picture?.profile_picture_url} /> : <span className='initials'>{option?.first_name?.[0] ?? '-'}</span>}
                                    <span className='user-description'>
                                      <span className='name'>{getFullname(option?.first_name, option?.last_name) ?? '-'}</span>
                                      <span className='email'>({option?.email})</span>
                                    </span>
                                  </div>
                                  <div className='right-side'>
                                    <Checkbox
                                      className='square-checkbox'
                                      checked={selected}
                                      icon={<img src={images.checkboxUnchecked} alt='' />}
                                      checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                    />
                                  </div>
                                </div>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} className='search-input-box' label={selectedMem?.length > 0 ? `${selectedMem.length} selected` : "Start typing to add member..."} variant="outlined" onChange={(event) => searchMembers(event)} />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className='chips-wrapper'>
                        <Stack direction="row" spacing={1}>
                          {selectedMem.length > 0 && selectedMem.map((item, idx) =>
                            <Chip key={item?.id} label={getFullname(item.first_name, item.last_name) ?? item?.email} deleteIcon={<span className='icon be-close-dark'></span>} onDelete={() => handleDelete(idx, true)} />
                          )}
                        </Stack>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="scheduleMeetingsSection" className='tab-panel-wrapper' aria-label="scheduleMeetingsSection">
                <div className='tab-content-section'>
                  <div className="title-block">
                    <div className='left-title'>
                      <Switch inputProps={{ 'aria-label': "authToggleSwitch" }} checked={scheduleFormVal['natasha_enabled'] ?? false} onChange={() => {setScheduleFormVal(prev => ({ ...prev, natasha_enabled: !prev['natasha_enabled'] })); resetErrorObj()}} />
                      <span className='enable-natasha-text'>Enable Natasha</span>
                      <img className='natasha-icon' src={images.natashaLogo} alt="" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <h6>Add title</h6>
                        <TextField className={errorObj['meetingTitle'] ? 'input-error' : ''} id="id12" inputProps={{ "data-testid": "title-field" }} variant="outlined" defaultValue={selectedMeeting?.title}
                          onChange={(e) => {setScheduleFormVal(prev => ({ ...prev, title: e.target.value })); resetErrorObj()}} />
                        <span className="form-error">{errorObj['meetingTitle']}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <h6>Start date</h6>
                        <div className='date-picker-wrap'>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              readOnly={!openCal}
                              open={openCal}
                              onClose={() => setOpenCal(false)}
                              format="DD/MM/YYYY"
                              disablePast
                              label={"Select"}
                              className={`${errorObj['startDate'] ? 'input-error' : ''} basic-date-picker ${moment(scheduleFormVal?.['start_date']).isSame(new Date(), "day") ? '' : 'input-reset'}`}
                              dayOfWeekFormatter={(day) => `${day}`}
                              defaultValue={selectedMeeting ? moment(selectedMeeting?.start_time) : null}
                              slotProps={{
                                textField: { size: "small", onClick: () => setOpenCal(true) },
                              }}
                              data-testid='date-picker'
                              onChange={(e) => {setScheduleFormVal(prev => ({ ...prev, start_date: e as any })); resetErrorObj()}} />
                          </LocalizationProvider>
                          <span className="form-error">{errorObj['startDate']}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <h6>Start time</h6>
                        <Select
                          labelId="select-start-time"
                          id="select-start-time"
                          label="Select"
                          inputProps={{'data-testid':"start-time"}}
                          className={`${errorObj['startTime'] ? 'input-error' : ''} select-box custom-autocomplete`}
                          defaultValue={selectedMeeting ? moment(selectedMeeting?.start_time)?.format('HH:mm')?.toString() : 'Select'}
                          MenuProps={{ classes: { list: 'select-popover' } }}
                          onChange={(e) => {setScheduleFormVal(prev => ({ ...prev, start_time: e.target.value as any })); resetErrorObj()}}
                        >
                          <MenuItem value="Select">Select</MenuItem>
                          {moment(scheduleFormVal?.['start_date']).isSame(new Date(), "day") ? 
                          timeArr.slice(0, timeArr.indexOf('00:00')).map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)
                          : freshTimeArr.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </Select>
                        <span className="form-error">{errorObj['startTime']}</span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <h6>Duration</h6>
                        <Select
                          labelId="select-duration"
                          id="select-duration"
                          label="Select"
                          inputProps={{'data-testid':"end-time"}}
                          className='select-box custom-autocomplete'
                          defaultValue={selectedMeeting ? moment.duration(moment(selectedMeeting?.end_time).diff(moment(selectedMeeting?.start_time)))?.asMinutes() : 'Select'}
                          onChange={(e) => {setScheduleFormVal(prev => ({ ...prev, duration: e.target.value as any })); resetErrorObj()}}
                        >
                          <MenuItem value="Select">Select</MenuItem>
                          <MenuItem value="30">30 Mins</MenuItem>
                          <MenuItem value="45">45 Mins</MenuItem>
                          <MenuItem value="60">60 Mins</MenuItem>
                        </Select>
                        <span className="form-error"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <h6>Add video conferencing</h6>
                        <Select
                          labelId="add-video"
                          id="add-video"
                          label=""
                          inputProps={{'data-testid':"video"}}
                          className='select-box select-video-conferencing'                          
                          defaultValue={'Bmeet'}
                          onChange={(e) => {setScheduleFormVal(prev => ({ ...prev, conferencing: 'Bmeet' })); resetErrorObj()}}
                        >
                          <MenuItem value="Select">Select</MenuItem>
                          <MenuItem value={'Bmeet'} className='select-video-menu-item'>
                            <span className='icon-wrap'>
                              <img src={images.builderLogoB} alt="Bmeet"/>
                            </span> Builder Meet                            
                          </MenuItem>
                          <MenuItem disabled value={'GoogleMeet'} className='select-video-menu-item'>
                            <span className='icon-wrap'>
                              <img src={images.logoGoogleMeet} alt="GM"/>
                            </span> Google Meet                            
                          </MenuItem>
                          <MenuItem disabled value={'ZoomMeet'} className='select-video-menu-item'>
                            <span className='icon-wrap'>
                              <img src={images.logoZoom} alt="Zoom"/>
                            </span> Zoom Meeting                          
                          </MenuItem>
                        </Select>
                        <span className="form-error"></span>
                      </div>
                    </div>
                  </div>
                  <div className="info-title-block">
                    <div className='left-title'>
                      <h6>Add member</h6>
                    </div>
                    <div className='right-side'>
                      <div>
                        <div className="has-right-data checkbox-container">
                          <FormControlLabel
                            className='checkbox-control'
                            control={<Checkbox
                              aria-label='checkbox'
                              icon={<img src={images.checkboxUnchecked} alt='' />}
                              checkedIcon={<img src={images.checkboxChecked} alt='' />}
                              onChange={() => setInviteAllForSchedule(prev => !prev)}
                            />}
                            label={
                              <span>Add all space members</span>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-12">
                      <div className="form-group searchbar-wrap">
                        <FormControl variant="filled">
                          {/* @ts-ignore */}
                          <Autocomplete
                            loading={loading}
                            // disabled={inviteAllForSchedule}
                            data-testid="autocomplete-search"
                            disableCloseOnSelect
                            multiple
                            freeSolo
                            disableClearable
                            filterOptions={(options) => options}
                            value={scheduleFormVal['meeting_users']}
                            id="searchMembersDropdown"
                            className='search-members-dropdown'
                            classes={{ popper: 'bmeet-search-dropdown' }}
                            onChange={(event, value) => addToSelected(value, false)}
                            options={memberList}
                            autoHighlight
                            renderTags={(value: string[], getTagProps) => ''}
                            getOptionLabel={(option) => option?.email}
                            popupIcon={<InputAdornment position="start"><em className='be-search'></em></InputAdornment>}
                            renderOption={(props, option, { selected }) => (
                              <Box component="span" {...props} key={option?.id}>
                                <div className='search-members-dropdown'>
                                  <div className='left-side'>
                                  {option?.profile_picture?.profile_picture_url ? <img alt="profile-pic" className='initials' src={option?.profile_picture?.profile_picture_url} /> : <span className='initials'>{option?.first_name?.[0] ?? '-'}</span>}
                                    <span className='user-description'>
                                      <span className='name'>{getFullname(option?.first_name, option?.last_name) ?? '-'}</span>
                                      <span className='email'>({option?.email})</span>
                                    </span>
                                  </div>
                                  <div className='right-side'>
                                    <Checkbox
                                      className='square-checkbox'
                                      checked={selected}
                                      icon={<img src={images.checkboxUnchecked} alt='' />}
                                      checkedIcon={<img src={images.checkboxChecked} alt='' />}
                                    />
                                  </div>
                                </div>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} className='search-input-box' label={scheduleFormVal['meeting_users']?.length > 0 ? `${scheduleFormVal['meeting_users']?.length} selected` : "Search members"} variant="outlined" onChange={(event) => searchMembers(event)} />
                            )}
                          />
                        </FormControl>
                        <div className='chips-wrapper'>
                          <Stack direction="row" spacing={1}>
                            {scheduleFormVal['meeting_users']?.length > 0 && scheduleFormVal['meeting_users']?.map((item, idx) =>
                              <Chip key={item?.id} label={getFullname(item.first_name, item.last_name) ?? item?.email} deleteIcon={<span className='icon be-close-dark'></span>} onDelete={() => handleDelete(idx, false)} />
                            )}
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        {/* @ts-ignore */}
                        <TextField 
                          className={`${errorObj['meetingDesc'] ? 'input-error' : ''} custom-textarea meeting-description`}
                          inputProps={{ 'data-testid' : 'project-description' }}
                          id="outlined-multiline-static"
                          label="Add description (optional)"
                          multiline
                          rows={4}
                          defaultValue={selectedMeeting?.description ?? ''}
                          variant="outlined"
                          name="description"                          
                          onChange={(e) => {setScheduleFormVal(prev => ({ ...prev, description: e.target.value })); resetErrorObj()}}
                        />
                        <span className="form-error">{errorObj['meetingDesc']}</span>
                      </div>
                    </div>
                  </div>
                </div>

              </TabPanel>
            </TabContext>
          </div>
        </div>        
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button aria-label="close-modal" variant="contained" className='sm-btn grey-btn' onClick={callMyTeamDialogClose}>Cancel</Button>
          {/* disabled={ !selectedMem.length && !inviteAllForInstant} */}
          {callMyTeamTabContentVal == 'instantMeetingsSection' && <Button className='sm-btn green-btn' variant="contained" aria-label="instant-meeting" onClick={startMeeting}>Start an instant meeting</Button>}
          {callMyTeamTabContentVal == 'scheduleMeetingsSection' && <Button className='sm-btn green-btn' variant="contained" aria-label="schedule-meeting" onClick={scheduleMeeting}>Schedule</Button>}
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default AddMemberToBmeetDialog;
