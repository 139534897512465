import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import images from '../../../assets/images';
import UnauthorizedAccess from '../../../components/UnauthorizedAccess';
import CreateSpaceGoalDialog from '../../../components/dialog/space-goal/CreateSpaceGoalDialog';
import instance from '../../../shared/interceptor';
import {
  getAllSpaceGoals,
  getCurrentSpaceDetail,
} from '../../../shared/services/common.service';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import OverflowTooltip from '../../../shared/shared-comp/OverflowTooltip';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import {
  companyState,
  permissionState,
  userPermissionState,
  userState,
} from '../../../shared/store/slices/userSlice';
import { SPACES, goalStatusList } from '../../../shared/util/constant';
import {
  formatDate,
  getColorCodeAndClassForGoals,
  globalError,
  isAllowedTo,
  isPrivateSpaceOwner,
  setLoader,
  sortByDate,
} from '../../../shared/util/util';
import Summary from '../../building/summary';
import SpaceTopNavigation from '../SupplyChainDistributionNavigation';
import '../spaces-overview.scss';
import './space-goals.scss';

function SpaceGoals() {
  const params = useParams();
  const currentSpaceSel = useSelector(currentSpaceState);
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const navigate = useNavigate();
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const userSel = useSelector(userState);

  const [anchorEl, setAnchorEl] = useState(null);
  const [spaceGoals, setSpaceGoals] = useState([]);
  const [searchSpaceGoals, setSearchSpaceGoals] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [archive, setArchive] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [sortStatus, setSortStatus] = useState('all');
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState({});

  useEffect(() => {
    getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
  }, []);

  const openThreeDotMenu = (event, idx) => {
    setAnchorEl({ [idx]: event.currentTarget });
  };

  const [openDialogue, setOpen] = useState(false);
  const handleClickOpen = (goal?) => {
    setOpen(true);
    if (goal) {
      setSelectedGoal(goal);
    }
  };

  useEffect(() => {
    getSpaceGoals();
  }, []);

  const handleClose = (e, render = null) => {
    setAnchorEl(null);
    setOpen(false);
    setSelectedGoal(null);
    if (render) {
      getSpaceGoals();
    }
  };

  const getSpaceGoals = async () => {
    try {
      setShowLoading(true);
      let res = await getAllSpaceGoals(dispatch, compSel, params);
      setSpaceGoals(
        sortByDate(
          res?.['space_goals'].filter((item) => item?.archive == false),
          'created_at',
          'Latest'
        )
      );
      setSearchSpaceGoals(
        sortByDate(res?.['space_goals'], 'created_at', 'Latest')
      );
      emptyState(res?.['space_goals']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setShowLoading(false);
    }
  };

  const emptyState = (data) => {
    if (!data?.length) {
      setShowEmptyState(true);
    }
  };

  const searchGoals = (event) => {
    let val = event?.target?.value;
    setSearchVal(val);
    if (archive) {
      if (val) {
        let data = searchSpaceGoals.filter((item) =>
          item?.name?.toLowerCase()?.includes(val?.toLowerCase())
        );
        setSpaceGoals(data);
        emptyState(data);
      } else {
        setSpaceGoals(searchSpaceGoals);
        emptyState(searchSpaceGoals);
      }
    } else {
      if (val) {
        let data = searchSpaceGoals.filter(
          (item) =>
            item?.name?.toLowerCase()?.includes(val?.toLowerCase()) &&
            item?.archive == archive
        );
        setSpaceGoals(data);
        emptyState(data);
      } else {
        setSpaceGoals(
          searchSpaceGoals.filter((item) => item?.archive == archive)
        );
        emptyState(searchSpaceGoals.filter((item) => item?.archive == archive));
      }
    }
  };

  const toggleArchive = () => {
    setArchive((prev) => !prev);
    if (archive) {
      let data = searchSpaceGoals.filter((item) => item?.archive == false);
      setSpaceGoals(data);
      emptyState(data);
    } else {
      setSpaceGoals(searchSpaceGoals);
      emptyState(searchSpaceGoals);
    }
  };

  const sortSpaceGoals = (e) => {
    let val = e?.target?.value;
    setSpaceGoals(sortByDate(spaceGoals, 'created_at', val));
  };

  const changeStatus = async (e, goal) => {
    e.stopPropagation();
    e.preventDefault();
    let statusVal = e?.target?.value;
    try {
      setStatusLoading({ [goal?.id]: true });
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${goal?.id}`;
      let payload = {
        name: goal?.name,
        description: goal?.description,
        status: statusVal,
        due_date: goal?.due_date,
        owner_id: goal?.owner_id,
        company_goal_ids: goal?.company_goals?.map((item) => item?.id),
        archive: goal?.archive,
      };
      await instance.patch(url, payload);
      handleClose(null, true);
      setArchive(true);
      dispatch(setSuccessMsg('Status updated successfully.'));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setStatusLoading({ [goal?.id]: false });
    }
  };

  const archiveGoal = async (goal) => {
    try {
      setLoader(dispatch, true);
      const url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/space_goals/${goal?.id}`;
      let payload = {
        name: goal?.name,
        description: goal?.description,
        status: goal?.status,
        due_date: goal?.due_date,
        owner_id: goal?.owner_id,
        company_goal_ids: goal?.company_goal_ids,
        archive: !goal?.archive,
      };
      await instance.patch(url, payload);
      handleClose(null, true);
      setArchive(false);
      dispatch(
        setSuccessMsg(
          `Status ${goal?.archive ? 'unarchived' : 'archived'} successfully.`
        )
      );
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoader(dispatch, false);
    }
  };

  const sortByStatus = (e) => {
    let val = e?.target?.value;
    setSortStatus(val);
    if (val == 'all') {
      setSpaceGoals(searchSpaceGoals);
    } else {
      setSpaceGoals(searchSpaceGoals.filter((goals) => goals.status == val));
    }
  };

  //=======================================
  return (
    <>
      {isAllowedTo(
        'read',
        'space_goal',
        permissions,
        { id: currentSpaceSel?.id, type: 'Space' },
        userPermissions
      ) && isPrivateSpaceOwner(currentSpaceSel, userSel) ? (
        <div
          className="space-overview-page space-goals-wrapper"
          data-testid="space-goals-page"
        >
          <div className="page-container top-space pb-0">
            <SpaceTopNavigation />

            <section className="overview-inner-section">
              <div className="page-content pt-0">
                <div className="goals-container space">
                  <div className="goals-left-col w-100">
                    <div className="secondary-header">
                      {/* Dropdowns and Search wrapper */}
                      <div className="page-filters-wrapper">
                        <div className="left-filter">
                          <div className="form-group sort-by">
                            <div className="dropdown-wrapper">
                              <span className="dropdown-text">
                                Sort by &nbsp;&nbsp;&nbsp;
                              </span>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                  },
                                  classes: { list: 'p-0' },
                                }}
                                className="borderless-dropdown sm-height no-label"
                                defaultValue={'Latest'}
                                onChange={sortSpaceGoals}
                              >
                                <MenuItem
                                  className="sorting-menu-item"
                                  value="Latest"
                                >
                                  Latest created
                                </MenuItem>
                                <MenuItem
                                  className="sorting-menu-item"
                                  value="Oldest"
                                >
                                  Oldest created
                                </MenuItem>
                              </Select>
                            </div>
                            <div className="dropdown-wrapper">
                              <span className="dropdown-text">
                                Show &nbsp;&nbsp;&nbsp;
                              </span>
                              <Select
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  },
                                  transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                  },
                                  classes: { list: 'p-0' },
                                }}
                                className="borderless-dropdown sm-height no-label"
                                value={sortStatus}
                                onChange={sortByStatus}
                              >
                                <MenuItem
                                  className="sorting-menu-item"
                                  value="all"
                                >
                                  All
                                </MenuItem>
                                <MenuItem
                                  className="sorting-menu-item"
                                  value="on_track"
                                >
                                  On Track
                                </MenuItem>
                                <MenuItem
                                  className="sorting-menu-item"
                                  value="off_track"
                                >
                                  Off Track
                                </MenuItem>
                                <MenuItem
                                  className="sorting-menu-item"
                                  value="accomplished"
                                >
                                  Accomplished
                                </MenuItem>
                                <MenuItem
                                  className="sorting-menu-item"
                                  value="progressing"
                                >
                                  Progressing
                                </MenuItem>
                                <MenuItem
                                  className="sorting-menu-item"
                                  value="not_started"
                                >
                                  Not Started
                                </MenuItem>
                              </Select>
                            </div>
                          </div>
                        </div>

                        <div className="right-filter">
                          <FormControl
                            variant="filled"
                            className="search-input"
                          >
                            {/* @ts-ignore */}
                            <FilledInput
                              inputProps={{ 'data-testid': 'searchGoals' }}
                              type="text"
                              className="xs-input search-input-wrap"
                              placeholder="Search goals"
                              startAdornment={
                                <InputAdornment position="start">
                                  <em className="be-search"></em>
                                </InputAdornment>
                              }
                              value={searchVal}
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  className="search-icon-wrap"
                                >
                                  {searchVal && (
                                    <img
                                      src={images.iconCrossCircle}
                                      alt=""
                                      onClick={(e) => {
                                        setSearchVal('');
                                        setSpaceGoals(
                                          searchSpaceGoals.filter(
                                            (item) => item?.archive == archive
                                          )
                                        );
                                      }}
                                    />
                                  )}
                                </InputAdornment>
                              }
                              onChange={searchGoals}
                            />
                          </FormControl>

                          <Tooltip
                            placement="top"
                            title={archive ? 'Hide archive' : 'Show archive'}
                            style={{ width: 'auto' }}
                          >
                            <Button
                              aria-label="archive-btn"
                              style={archive ? { background: '#f3ebff' } : {}}
                              variant="contained"
                              className="xs-btn is-icon-btn btn-archive"
                              onClick={toggleArchive}
                            >
                              <em className="icon be-archive"></em>
                            </Button>
                          </Tooltip>
                          {isAllowedTo(
                            'update',
                            'space_goal',
                            permissions,
                            { id: currentSpaceSel?.id, type: 'Space' },
                            userPermissions
                          ) && (
                            <Button
                              aria-label="add-goal-btn"
                              variant="contained"
                              className="xs-btn purple-btn"
                              onClick={handleClickOpen}
                            >
                              Add goal
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* No data wrapper */}
                    {!spaceGoals?.length && showEmptyState && (
                      <div className="no-data-wrapper">
                        <em className="thumb">
                          <img
                            src={images.emptyStateIllustration}
                            alt="Spaces Flag"
                          />
                        </em>
                        <h4 aria-label="no-space">No goals yet</h4>
                        <p>Add goals to keep your development on track.</p>
                        {isAllowedTo(
                          'update',
                          'space_goal',
                          permissions,
                          { id: currentSpaceSel?.id, type: 'Space' },
                          userPermissions
                        ) && (
                          <Button
                            variant="contained"
                            className="sm-btn grey-btn"
                            onClick={handleClickOpen}
                          >
                            Add goals
                          </Button>
                        )}
                      </div>
                    )}
                    <div className="tabListView">
                      {showLoading && <LoaderComp maxHeight="32rem" />}
                      {/* Goal card wrapper */}
                      {spaceGoals?.map((item, idx) => (
                        <div
                          className="incread-by-box"
                          key={item?.id}
                          aria-label="space-goals-list"
                          onClick={() =>
                            navigate(
                              `/company/spaces/${params?.id}/space-goals/${item?.id}`
                            )
                          }
                        >
                          <div className="title-head">
                            {/* <h4><span className="cr-pointer">Convert 10 lead weekly to increase sales</span> <span className="private">Private</span> <em className="icon be-archive"></em></h4> */}
                            <h4>
                              <span className="cr-pointer">{item?.name}</span>{' '}
                            </h4>
                            <Button
                              aria-label="three-dot-btn"
                              className="three-dot-btn xs-btn is-icon-btn"
                              onClick={(event) => {
                                event.stopPropagation();
                                openThreeDotMenu(event, idx);
                              }}
                            >
                              <em className="icon be-vertical-dots"></em>
                            </Button>
                            <Menu
                              id="three-dot-menu"
                              anchorEl={anchorEl?.[idx]}
                              open={Boolean(anchorEl?.[idx])}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              className={'three-dot-menu-paper'}
                              onClose={(e) => {
                                (e as any).stopPropagation();
                                handleClose(e, null);
                              }}
                            >
                              <MenuItem
                                className="toggle-menu-item-row"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleClickOpen(item);
                                }}
                              >
                                <img src={images.editIcon} alt="" /> Edit
                              </MenuItem>
                              <MenuItem
                                aria-label="archive-goal-btn"
                                className="toggle-menu-item-row"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  archiveGoal(item);
                                }}
                              >
                                <em className="icon be-archive"></em>{' '}
                                {item?.archive ? 'Unarchive' : 'Archive'}
                              </MenuItem>
                            </Menu>
                          </div>
                          <div className="progress-wrap">
                            <div className="text-content">
                              {item?.description}
                            </div>
                            <div className="progress-bar">
                              <Box sx={{ width: '100%' }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={
                                    item?.build_cards_count
                                      ? (item?.build_cards_accomplished /
                                          item?.build_cards_count) *
                                        100
                                      : 0
                                  }
                                />
                              </Box>
                              <div className="progress-text">
                                <strong>
                                  {item?.build_cards_accomplished}/
                                  {item?.build_cards_count}
                                </strong>
                                <span> Buildcards accomplished</span>
                              </div>
                            </div>
                          </div>
                          <div className="tags-wrapper">
                            <Stack direction="row" spacing={1}>
                              {item?.company_goals
                                ?.filter((go) => !go?.archive)
                                ?.slice(0, 2)
                                ?.map((goal) => (
                                  <Chip
                                    label={
                                      <OverflowTooltip
                                        text={goal?.name}
                                        tooltip={goal?.name}
                                        placement={'top'}
                                      />
                                    }
                                    className="purple-tag"
                                    key={goal?.id}
                                  />
                                ))}
                              {item?.company_goals?.filter((go) => !go?.archive)
                                ?.length > 2 && (
                                <Chip
                                  label={`+${item?.company_goals?.filter((go) => !go?.archive)?.length - 2}`}
                                  className="purple-tag"
                                />
                              )}
                            </Stack>
                          </div>
                          <div className="box-footer">
                            <span className="date-box">
                              Target {formatDate(item?.due_date)}
                            </span>
                            <span className="status">
                              {statusLoading[item?.id] && <LoaderComp />}
                              {/* { onTrack: backgroundColor:'#69F0AE', border:'1px solid #00E676' },{ Not started: backgroundColor:'#DFE0E6', border:'1px solid #9B9FB1' },{ progressing: backgroundColor:'#FFD740', border:'1px solid #FFC400' },{ offTrack: backgroundColor:'#FF5546', border:'1px solid #FFB3AC' },{ accomplished: backgroundColor:'#00E676', border:'1px solid #00C853' } */}
                              {!statusLoading[item?.id] && (
                                <>
                                  <em
                                    className="color-dot left"
                                    style={{
                                      background: getColorCodeAndClassForGoals(
                                        item?.status,
                                        item?.archive
                                      ).color,
                                    }}
                                  ></em>
                                  <Select
                                    data-testid="select-status-field"
                                    inputProps={{}}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      },
                                      transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                      },
                                      classes: { list: 'p-0' },
                                    }}
                                    className="select-status borderless-dropdown"
                                    value={item?.status}
                                    displayEmpty
                                    label="Status"
                                    onClose={(e) =>
                                      (e as any).stopPropagation()
                                    }
                                    onChange={(e) => {
                                      (e as any).stopPropagation();
                                      changeStatus(e, item);
                                    }}
                                  >
                                    {goalStatusList?.map((status) => (
                                      <MenuItem
                                        onClick={(e) => e.stopPropagation()}
                                        key={status?.id}
                                        value={status?.value}
                                        className="status-menu-item"
                                      >
                                        <em
                                          className="dot-sm"
                                          style={{
                                            backgroundColor: status?.color,
                                            border: status?.border,
                                          }}
                                        ></em>{' '}
                                        {status?.text}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* <div className="incread-by-box disabled"> 
                    <div className="title-head">
                      <h4><span className="cr-pointer">Convert 10 lead weekly to increase sales</span> <em className="icon be-archive"></em></h4>                       
                      <Button className="three-dot-btn xs-btn is-icon-btn" onClick={openThreeDotMenu}><em className='icon be-vertical-dots'></em></Button>
                      <Menu
                        id="three-dot-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        className={'three-dot-menu-paper'}
                      >
                        <MenuItem className='toggle-menu-item-row' ><em className='icon be-edit'></em> Edit</MenuItem>
                        <MenuItem className='toggle-menu-item-row'><em className='icon be-archive'></em> Archive</MenuItem>
                      </Menu>
                    </div>
                    <div className="progress-wrap">
                      <div className="text-content">Increase number of employee worldwide by 60%, this includes online and in store.</div>
                      <div className="progress-bar">                    
                        <Box sx={{ width: '100%' }}>
                          <LinearProgress variant="determinate" value={0} />
                        </Box>  
                        progressbar here!                     
                        <div className="progress-text">
                          <strong>0/0</strong><span> Buildcards accomplished</span>
                        </div>
                      </div>
                    </div>
                    <div className="tags-wrapper">
                      <Stack direction="row" spacing={1}>
                        <Chip label='Reduce delivery time by 2 days' className="purple-tag" />
                        <Chip label='Reduce delivery time by 2 days' className="purple-tag" />
                        <Chip label='+1' className="purple-tag" />
                      </Stack>
                    </div>
                    <div className="box-footer">
                      <span className="date-box">Target 20.10.2024</span>
                      <span className="status">
                        {/* { onTrack: backgroundColor:'#69F0AE', border:'1px solid #00E676' },{ Not started: backgroundColor:'#DFE0E6', border:'1px solid #9B9FB1' },{ progressing: backgroundColor:'#FFD740', border:'1px solid #FFC400' },{ offTrack: backgroundColor:'#FF5546', border:'1px solid #FFB3AC' },{ accomplished: backgroundColor:'#00E676', border:'1px solid #00C853' } */}
                    {/* Accomplished <img src={images.checkAccomplishedRounded} alt="icon"/>                         
                      </span>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>

              <Summary />
            </section>
          </div>
        </div>
      ) : (
        <UnauthorizedAccess />
      )}

      {/* Create Space goal dialogue start */}
      {openDialogue && (
        <CreateSpaceGoalDialog
          {...{ openDialogue, handleClose, selectedGoal }}
        />
      )}
      {/* Create Space goal dialogue end */}
    </>
  );
}

export default SpaceGoals;
