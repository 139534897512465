/* eslint eqeqeq: 0 */
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from "@mui/material/Menu";
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import instance from '../interceptor';
import { currentSpaceState } from '../store/slices/companySlice';
import { setSuccessMsg } from '../store/slices/successSlice';
import { companyState, permissionState, userPermissionState } from '../store/slices/userSlice';
import { SPACES } from '../util/constant';
import { globalError, isAllowedTo } from '../util/util';
import LoaderComp from './LoaderComp';
import OverflowTooltip from './OverflowTooltip';


function GoalsTags({ card, idx, spaceGoals, searchSpaceGoalList, updateGoals, goalNumber = true }) {
  const dispatch = useDispatch();
  const companySel = useSelector(companyState);
  const currentSpace = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const params = useParams();

  const [anchorGoalEl, setGoalAnchorEl] = useState(null);
  const [goalTagLoading, setGoalTagLoading] = useState(false);

  const entity = { id: currentSpace?.id, type: 'Space' }

  const tagBuildcard = async (id, spaceGoal, spaceId = null) => {
    try {
      setGoalTagLoading(true);
      const url = `${SPACES.replace(':id', companySel?.id)}/${params?.id ?? spaceId}/build_cards/${id}/tag_space_goal`;
      const payload = {
        space_goal_ids: [spaceGoal?.id]
      }
      await instance.post(url, payload);
      updateGoals(spaceGoal, card);
      dispatch(setSuccessMsg('Space goal added to Buildcard successfully.'));
      handleClose();
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setGoalTagLoading(false);
    }
  }

  const handleClose = () => {
    setGoalAnchorEl(null);
  }

  const handleClick = (event, idx) => {
    event.preventDefault();
    event.stopPropagation();
    setGoalAnchorEl({ [idx]: event.currentTarget });
  };

  const deleteTaggedGoal = async (enterpriseId, goal, spaceId = null) => {
    try {
      setGoalTagLoading(true);
      const url = `${SPACES.replace(':id', companySel?.id)}/${params?.id ?? spaceId}/build_cards/${enterpriseId}/remove_space_goal/${goal?.id}`;
      await instance.delete(url);
      updateGoals(goal, card, true);
      dispatch(setSuccessMsg('Space goal deleted from Buildcard successfully.'));
      handleClose();
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setGoalTagLoading(false);
    }
  }

  // check for CIO / space owner / buildcard creator permissions
  const canDestroySpaceGoalBuildcards = (card) => {
    return (isAllowedTo('destroy', 'space_goal_buildcard', permissions, { id: currentSpace?.id, type: 'Space' }, userPermissions) || isAllowedTo('destroy', 'space_goal_buildcard', permissions, { id: card?.enterprise_id, type: 'BuildCard' }, userPermissions))
  }

  //===========================================
  return (
    <div className="icon-btn-wrap" data-testid='goal-tags-comp' key={idx} onClick={e => e?.stopPropagation()}>
      <Tooltip placement='top-end' arrow title={"Add goal"} classes={{ popper: 'info-tooltip' }}>
        {isAllowedTo('read', 'space_goal', permissions, entity, userPermissions) && goalNumber ?
          <Button variant="contained" className="btn-goal-rounded" onClick={(e) => { { (e as any)?.stopPropagation(); handleClick(e, idx) } }}><em className='icon be-business-goals' style={{ position: 'relative' }}></em> <span>{card?.space_goals?.length}</span></Button> :
          <Button variant="contained" className="btn-goal-rounded" onClick={(e) => { { (e as any)?.stopPropagation(); handleClick(e, idx) } }}>{!card?.space_goals?.length ? <em className='icon be-business-goals'></em> : <>{`+${card?.space_goals?.length}`}</>}</Button>}
      </Tooltip>
      <div className="tag-menu-wrapper">
        <Menu
          id="goals-tag-menu"
          className="taglist-menu cards"
          anchorEl={anchorGoalEl?.[idx]}
          open={Boolean(anchorGoalEl?.[idx])}
          onClose={e => { (e as any)?.stopPropagation(); handleClose() }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {card?.space_goals?.length > 0 && <div className='selected-tags'>
            {card?.space_goals?.map(gol => <Stack key={gol?.id} direction="row" className='purple-chips' spacing={1}>
              <Chip
                label={<OverflowTooltip text={gol?.name} tooltip={gol?.name} placement={'top'} />}
                onClick={e => e.stopPropagation()}
                onDelete={() => deleteTaggedGoal(card?.enterprise_id, gol, params?.id ? null : card?.space_id)}
                deleteIcon={
                  canDestroySpaceGoalBuildcards(card) ? <span data-testid='delete-tag-goal' className='icon be-close-dark sm-close'></span> : <></>
                } />
            </Stack>)}
            {/* {!card?.space_goals?.length && <div className="empty-tags-wrapper">
              <p className="empty-text">No goals added yet</p>
            </div>} */}
          </div>}
          <div className="search-container">
            {goalTagLoading && <LoaderComp style={{ display: 'flex' }} />}
            {!goalTagLoading && <FormControl variant="filled">
              <FilledInput type="text" className="xs-input search-tag-input" placeholder='Search space goals...'
                startAdornment={
                  <InputAdornment position="start"></InputAdornment>
                }
                onChange={(e) => searchSpaceGoalList(e, card?.space_id)}
                onKeyDown={event => event?.stopPropagation()}
                onClick={event => event?.stopPropagation()}
              />
            </FormControl>}
          </div>
          <div style={{ maxHeight: '260px' }}>
            {spaceGoals?.map(go => <div className='tag-row' key={go?.id}>
              <Stack key={go?.id} direction="row" className='purple-chips' data-testid='space-goal-chip' spacing={1}>
                <Chip onClick={(e) => { e?.stopPropagation(); tagBuildcard(card?.enterprise_id, go, params?.id ? null : card?.space_id) }} label={<OverflowTooltip text={go?.name} tooltip={go?.name} placement={'top'} />} />
              </Stack>
            </div>)}
          </div>
        </Menu>
      </div>
    </div>
  )
}

export default GoalsTags;
